<template>
  <Modal v-model="modal" title="商品配置历史记录" width="1000" footer-hide>
    <h4>商品基础信息</h4>
    <Row class="p-b-5 p-t-10">
      <i-col span="3" ><span class="title">商品名称 </span ></i-col >
      <i-col span="9" >{{commodityBean.productName}}</i-col >

      <i-col span="3" ><span class="title">商品编码 </span ></i-col >
      <i-col span="9" >{{commodityBean.code}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">商品类型 </span ></i-col >
      <i-col span="9" >{{commodityBean.productTypeName}}</i-col >

      <i-col span="3" ><span class="title">核销档期 </span ></i-col >
      <i-col span="9" >{{commodityBean.verifyPeriod}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">置换单价 </span ></i-col >
      <i-col span="9" >{{commodityBean.replacementPrice}}</i-col >

      <i-col span="3" ><span class="title">总商品数量 </span ></i-col >
      <i-col span="9" >{{commodityBean.totalNum}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">总销售数量 </span ></i-col >
      <i-col span="9" >{{commodityBean.totalSoldNum}}</i-col >

      <i-col span="3" ><span class="title">商品上架数量 </span ></i-col >
      <i-col span="9" >{{commodityBean.listNum}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">上架商品销售数量 </span ></i-col >
      <i-col span="9" >{{commodityBean.soldNum}}</i-col >

      <i-col span="3" ><span class="title">商品初始价格 </span ></i-col >
      <i-col span="9" >{{commodityBean.initPrice}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">最低保留价格 </span ></i-col >
      <i-col span="9" >{{commodityBean.minPrice}}</i-col >

      <i-col span="3" ><span class="title">商品上架日期 </span ></i-col >
      <i-col span="9" >{{commodityBean.saleDate}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">预计下架日期 </span ></i-col >
      <i-col span="9" >{{commodityBean.expectDownDate}}</i-col >

      <i-col span="3" ><span class="title">商品现价 </span ></i-col >
      <i-col span="9" >{{commodityBean.price}}</i-col >
    </Row>
    <Row class="p-b-5">
      <i-col span="3" ><span class="title">开启动态定价 </span ></i-col >
      <i-col span="9" >
        <Icon v-if="!commodityBean.dynamicFlag" type="md-close" color="#ed4014" size="14" />
        <Icon v-else type="md-checkmark" color="#44BD32" size="14" />
      </i-col >

      <i-col span="3" ><span class="title">开启动态核销期 </span ></i-col >
      <i-col span="9" >
        <Icon v-if="!commodityBean.openDynamicVerify" type="md-close" color="#ed4014" size="14" />
        <Icon v-else type="md-checkmark" color="#44BD32" size="14" />
      </i-col >
    </Row>

    <h4 class="m-t-20 m-b-10">历史配置记录</h4>
    <Table
      size="small"
      :columns="historyColumn"
      :data="historyData"
      stripe
    ></Table>
  </Modal>
</template>

<script>
import { getDutchRecordList } from '@/api/displace/productDutch'

export default {
  data () {
    return {
      modal: false,
      commodityBean: {},
      historyColumn: [
        { title: '创建日期', key: 'createTime', width: 155 },
        { title: '上架日期', key: 'saleDate' },
        { title: '预计下架日期', key: 'expectDownDate' },
        { title: '商品初始价', key: 'initPrice' },
        { title: '最低保留价格', key: 'minPrice' },
        { title: '上架数量', key: 'listNum' }
      ],
      historyData: []
    }
  },
  methods: {
    showHistoryModal (params) {
      this.modal = true
      this.commodityBean = params

      const query = {
        productId: params.productId
      }
      getDutchRecordList(query).then(res => {
        this.historyData = res
      })
    }
  }
}
</script>
