import request from '@/utils/requestV2'
const qs = require('qs')

// 分页查询商品列表
export function listProducts (data) {
  return request({
    url: '/ooh-displace/v1/productDutch/pageItemList',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据商品id查询动态定价历史配置记录信息
export function getDutchRecordList (data) {
  return request({
    url: '/ooh-displace/v1/productDutch/getDutchRecordList',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 更新商品动态定价配置信息
export function updateDutchRecord (data) {
  return request({
    url: '/ooh-displace/v1/productDutch/updateDutchRecord',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取商品状态枚举值
export function listItemStatus (data) {
  return request({
    url: '/ooh-displace/v1/productDutch/listItemStatus',
    method: 'post',
    data: qs.stringify(data)
  })
}
